function PrintDeliveryNumberLabel(deliveryNumber, dentist, dealer, patient, url, count) {
    //alert("Print Label inside Js file");
    try {
        $.get(url, function (labelXml) {
            var label = window.dymo.label.framework.openLabelXml(labelXml);
            var labelSet = new window.dymo.label.framework.LabelSetBuilder();

            for (var i = 0; i < count; i++) {
                var record = labelSet.addRecord();
                record.setText("Label_DeliveryNumber", deliveryNumber);
                record.setText("BarCode_DeliveryNumber", deliveryNumber);
                record.setText("Label_Dentist", dentist);
                record.setText("Label_Dealer", dealer);
                record.setText("Label_Patient", patient);
            }

            var printerName = GetDymoPrinters();
            //updatePreview(label);
            // finally print the label
            label.print(printerName, '', labelSet);
        }, "text");
    }
    catch (e) {
        alert(e.message || e);
    }
}

function updatePreview(label) {
    // if (!label)
    //     return;

    var pngData = label.render();

    var labelImage = document.getElementById('label');
    labelImage.src = "data:image/png;base64," + pngData;
}

function GetDymoPrinters() {
    try{
        dymo.label.framework.init();
    }catch{

    }

    var printers = window.dymo.label.framework.getPrinters();
    if (printers.length == 0)
        throw "No DYMO printers are installed. Install DYMO printers. \n\nhttp://global.dymo.com";

    var printerName = "";
    for (var i = 0; i < printers.length; ++i) {
        var printer = printers[i];
        if (printer.printerType == "LabelWriterPrinter") {
            printerName = printer.name;
            break;
        }
    }

    if (printerName == "")
        throw "No LabelWriter printers found. Install LabelWriter printer";

    return printerName;
}

var SendToDymo = function (data, template, fnCallback) {

    try {

        var printerName = GetDymoPrinters();

        $.get(template, "", "text").done(function (labelXml) {

            var label = window.dymo.label.framework.openLabelXml(labelXml);
            var labelSet = new window.dymo.label.framework.LabelSetBuilder();
            var logoBaseUrl = dentApp.common.getProperty('dealerLogoBaseUrl');

            var currentDealerLogo;

            var flags = {}, prevLogos = {};
            var uniqueDealers = data.filter(function (entry) {
                if (flags[entry.Dealer.Id]) {
                    return false;
                }
                flags[entry.Dealer.Id] = true;
                return true;
            }).map(function (itm) {
                currentDealerLogo = itm.Dealer.Logo ? itm.Dealer.Logo : 'defaultLogo3.jpg';

                if (!prevLogos[currentDealerLogo])
                    prevLogos[currentDealerLogo] = window.dymo.label.framework.loadImageAsPngBase64(logoBaseUrl + currentDealerLogo);

                return {
                    dealerId: itm.Dealer.Id,
                    logoBase64: prevLogos[currentDealerLogo]
                };
            });

            for (var j = 0; j < data.length; j++) {

                var record = labelSet.addRecord();

                record.setText("BarCode", data[j].Dentist.CompanyNo || '');
                record.setText("CompanyName", data[j].Dentist.Group || '');
                record.setText("DentistName", data[j].Dentist.Name || '');
                record.setText("PostalStreetAddress", data[j].Dentist.Street || '');
                record.setText("PostalStreetAddress2", '');
                record.setText("DentistPostalAddress", (data[j].Dentist.PostalCode || '') + ' ' + (data[j].Dentist.City || ''));
                record.setText("DentistCountry", data[j].Dentist.Country || '');
                record.setText("LabelTag", data[j].Dentist.LabelTag || '');

                record.setText("CompanyName_Dealer", data[j].Dealer.Name || '');
                record.setText("CompanyPostalStreetAddress_Dealer", data[j].Dealer.Street || '');
                record.setText("CompanyPostalAddress_Dealer", (data[j].Dealer.PostalCode || '') + ' ' + (data[j].Dealer.City || ''));
                record.setText('GRAFIK', ($.grep(uniqueDealers, function (obj) { return obj.dealerId === data[j].Dealer.Id; })[0].logoBase64) || '');

            }

            // finally print the label(s)
            label.print(printerName, '', labelSet);

            if (fnCallback && typeof (fnCallback) === "function") {
                fnCallback();
            }
        });
    } catch (e) {
        if (fnCallback && typeof (fnCallback) === "function") {
            fnCallback();
        }
        alert(e.message || e);
    }


};

function PrintDentistLabel(companyNumber, companyGroup, companyName, postalStreetAddress, postalStreetAddress2, dentistPostalAddress, dentistCountry, dentistPhone, url, labelTag, sender) {
    try {

        var printerName = GetDymoPrinters();

        $.get(url, function (labelXml) {
            var label = window.dymo.label.framework.openLabelXml(labelXml);
            var labelSet = new window.dymo.label.framework.LabelSetBuilder();


            for (var j = 0; j < 1; j++) {

                var record = labelSet.addRecord();

                record.setText("BarCode", companyNumber);
                record.setText("CompanyName", companyGroup || '');
                record.setText("DentistName", companyName);
                record.setText("PostalStreetAddress", postalStreetAddress);
                //record.setText("PostalStreetAddress2", postalStreetAddress2);
                record.setText("DentistPostalAddress", dentistPostalAddress);
                record.setText("DentistCountry", dentistCountry);
                record.setText("LabelTag", labelTag || '');

                if (sender !== null && sender !== 'undefined') {
                    record.setText("CompanyName_Dealer", sender.Name);
                    record.setText("CompanyPostalStreetAddress_Dealer", sender.Street);
                    record.setText("CompanyPostalAddress_Dealer", sender.PostalCode + ' ' + sender.City);
                    //record.setText("CompanyName_dealer", sender.City);
                    //record.setText("CompanyName_dealer", sender.CountryName);

                    // var logoBaseUrl ="./Images";
                    // var logoFileName = sender.LogoFileName || 'defaultLogo3.jpg';
                    // var logoBase64 = window.dymo.label.framework.loadImageAsPngBase64(logoBaseUrl + logoFileName);

                    // record.setText('GRAFIK', logoBase64);
                }
                else {
                    record.setText("CompanyName_Dealer", '');
                    record.setText("CompanyPostalStreetAddress_Dealer", '');
                    record.setText("CompanyPostalAddress_Dealer", '');
                }

            }
            //updatePreview(label);
            // finally print the label
            label.print(printerName, '', labelSet);
        }, "text");
    }
    catch (e) {
        alert(e.message || e);
    }
};



function GetLabelTemplateUrl(rootPath, countryName) {

    if (countryName == null || countryName == '' || countryName == 'undefined')
        return rootPath + 'Dentist_label.txt';
    if (countryName == 'USA')
        return rootPath + 'Dentist_label_US.txt';
    if (countryName == 'Norway')
        return rootPath + 'Dentist_label_NO.txt';
    else
        return rootPath + 'Dentist_label.txt';
};

